import React, { useEffect } from 'react';
import './App.css';

const iframeStyles = {
  width: '100%',
  height: '100%',
  border: 'none',
  position: 'absolute',
  top: 0,
  left: 0,
  overflow: 'hidden'
};

function App() {
  useEffect(() => {
    const handleMessage = event => {
      // Handle messages received from the iframe
      if (event.origin !== 'https://topmate.io') return; // Ensure it's from the expected origin
      // Handle the message here
      console.log('Message received from iframe:', event.data);
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <iframe src="https://topmate.io/hlabs" title="React Documentation" style={iframeStyles} />
  );
}

export default App;
